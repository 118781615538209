import { ref, Ref } from 'vue';
import {
    ListHeaderItem, RequestData, SearchKey, getSearch
} from '@/components/common/list';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import { getInitSortField } from '@/methods/sort-table';

// 导航
const breadHeaders = [{ label: WordList.RDeviceResidents }];

// search列表
const searchKeyList: SearchKey = [
    {
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }
];

const headers: Array<ListHeaderItem> = [{
    label: WordList.DeviceDetailDetailUnitName,
    name: 'UnitName'
}, {
    label: WordList.RDeviceSearchLabelRoomName,
    name: 'RoomName',
    headerType: 'customize'
}, {
    label: WordList.RDeviceSearchLabelRoomNumber,
    name: 'RoomNumber',
    headerType: 'customize'
}, {
    label: WordList.TabelPersonUserInHtmlName,
    name: 'Name',
    headerType: 'customize'
}, {
    label: WordList.TabelPropertyManageEmail,
    name: 'Email'
}, {
    label: WordList.ProperAllTextMobileNumber,
    name: 'MobileNumber'
}, {
    label: WordList.ProperAllTextAppStatus,
    name: 'Initialization'
},
{
    label: WordList.NavInHtmlMenuUser2,
    name: 'SubCount',
    type: 'customize'
}, {
    label: WordList.RDeviceListTanleActive,
    name: 'Active'
}, {
    label: WordList.TabelExpretimeBoxSpecificTime,
    name: 'ExpireTime'
}];

const initData = (
    state: '0'|'1'|'all'
) => {
    // 获取初始排序
    const initSortRes = getInitSortField('pmOldComResident');
    // list请求借口及请求参数
    const requestData: Ref<RequestData> = ref({
        url: 'v3/web/community/resident/getList',
        param: getSearch({
            Build: 'all',
            Room: 'all',
            Stauts: state,
            Active: 'all',
            serchKey: 'Name',
            serchValue: '',
            Sort: initSortRes.sort,
            SortField: initSortRes.sortField
        })
    });

    // list搜索查询处理
    const updateList = ref(0);
    const saveParamInPath = ref(false);
    const searchList = () => {
        updateList.value += 1;
        saveParamInPath.value = true;
    };

    // list删除处理
    const deleteMultipleUrl = 'v3/web/community/mainUser/delete';
    const multipleDelMsg = (value: Array<object>) => {
        let multipleMessage = WordList.ProperAllTextPMDeleteUserTip;
        if (value.length > 1) {
            multipleMessage = WordList.ProperAllTextPMDeleteUserTip2;
        }
        return multipleMessage;
    };

    const add = () => {
        router.push(
            `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldSetResident}`
        );
    };

    const edit = (val: {ID: string}) => {
        router.push(
            `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldSetResident}?id=${val.ID}`
        );
    };

    const info = (val: {ID: string}) => {
        router.push(
            `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResidentInfo}?id=${val.ID}`
        );
    };

    return {
        breadHeaders,
        requestData,
        searchKeyList,
        searchList,
        updateList,
        saveParamInPath,
        multipleDelMsg,
        deleteMultipleUrl,
        headers,
        add,
        edit,
        info
    };
};

export default initData;
