
import { defineComponent, PropType, watch } from 'vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import initData from '@/views/pm/old-community/resident/init-data';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import { isCNServer } from '@/util/location';
import {
    listPagination
} from '@/components/common/list';
import AddButton from '@/components/common/add-button/index.vue';
import { updateSortField, getSortImg } from '@/methods/sort-table';

export default defineComponent({
    components: {
        PropertyBreadCrumb,
        listPagination,
        AddButton
    },
    props: {
        state: {
            type: String as PropType<'0'|'1'|'all'>,
            default: 'all'
        }
    },
    setup(props) {
        const {
            breadHeaders,
            requestData,
            searchKeyList,
            searchList,
            updateList,
            saveParamInPath,
            multipleDelMsg,
            deleteMultipleUrl,
            headers,
            add,
            edit,
            info
        } = initData(props.state);

        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();

        // 监听社区选择楼栋，动态改变apt
        watch(() => requestData.value.param.Build, (buildId) => {
            requestData.value.param.Room = 'all';
            setRoomOption(String(buildId));
        });

        // 获取排序方式, 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            requestData.value.param.Sort = sort;
            requestData.value.param.SortField = sortField;
            updateList.value += 1;
        }
        return {
            breadHeaders,
            requestData,
            buildOptions,
            aptOptions,
            searchKeyList,
            setRoomOption,
            isCNServer: isCNServer(),
            searchList,
            updateList,
            saveParamInPath,
            multipleDelMsg,
            deleteMultipleUrl,
            headers,
            add,
            edit,
            info,
            getSortImg,
            getSortRes
        };
    }
});
